define(["require", "exports", "react"], function (require, exports, React) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    /**
     * icon_source: ic_sts_checkboxbulk_default_16_w.svg
     */
    function CheckboxbulkIcon16(props) {
        return (React.createElement("svg", { width: "16", height: "16", fill: "none", viewBox: "0 0 16 16", className: "svg-icon " + (props.table ? 'table-icon ' : '') + (props.className || ''), "data-test-id": props['data-test-id'], name: "CheckboxbulkIcon16", focusable: "false" },
            React.createElement("path", { fill: "#fff", fillRule: "evenodd", d: "M3.5 8a1 1 0 011-1h7a1 1 0 110 2h-7a1 1 0 01-1-1z", clipRule: "evenodd" })));
    }
    exports.CheckboxbulkIcon16 = CheckboxbulkIcon16;
});
