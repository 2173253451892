define(["require", "exports", "react"], function (require, exports, React) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    /**
     * icon_source: ic_srvx_sortincrease_default_16_w.svg
     */
    function SortincreaseSrvxIcon16(props) {
        return (React.createElement("svg", { width: "16", height: "16", fill: "none", viewBox: "0 0 16 16", className: "svg-icon " + (props.table ? 'table-icon ' : '') + (props.className || ''), "data-test-id": props['data-test-id'], name: "SortincreaseSrvxIcon16", focusable: "false" },
            React.createElement("path", { className: "sort-fill", d: "M9 4a1 1 0 00-2 0v6.42l-.875-.7a1 1 0 10-1.25 1.56l2.5 2a1 1 0 001.25 0l2.5-2a1 1 0 10-1.25-1.56l-.875.7V4z" })));
    }
    exports.SortincreaseSrvxIcon16 = SortincreaseSrvxIcon16;
});
