define(["require", "exports", "react"], function (require, exports, React) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    /**
     * icon_source: ic_nav_paginatorleft_default_32_w.svg
     */
    function PaginatorleftIcon32(props) {
        return (React.createElement("svg", { width: "32", height: "32", fill: "none", viewBox: "0 0 32 32", className: "svg-icon " + (props.table ? 'table-icon ' : '') + (props.className || ''), "data-test-id": props['data-test-id'], name: "PaginatorleftIcon32", focusable: "false" },
            React.createElement("circle", { cx: "16", cy: "16", r: "16", className: "paginator-secondary-fill", transform: "rotate(-180 16 16)" }),
            React.createElement("path", { className: "paginator-primary-fill", fillRule: "evenodd", d: "M18.707 21.707a1 1 0 000-1.414L14.414 16l4.293-4.293a1 1 0 00-1.414-1.414l-5 5a1 1 0 000 1.414l5 5a1 1 0 001.414 0z", clipRule: "evenodd" })));
    }
    exports.PaginatorleftIcon32 = PaginatorleftIcon32;
});
