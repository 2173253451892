define(["require", "exports", "react"], function (require, exports, React) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    /**
     * icon_source: ic_srv_emptytable_default_64_w.svg
     */
    function EmptytableIcon64(props) {
        return (React.createElement("svg", { width: "64", height: "64", fill: "none", viewBox: "0 0 64 64", className: "svg-icon " + (props.table ? 'table-icon ' : '') + (props.className || ''), "data-test-id": props['data-test-id'], name: "EmptytableIcon64", focusable: "false" },
            React.createElement("path", { className: "primary-fill", d: "M24 19a1 1 0 000 2h5a1 1 0 000-2h-5zm0 8a1 1 0 000 2h5a1 1 0 000-2h-5zm-1 9a1 1 0 011-1h16a1 1 0 010 2H24a1 1 0 01-1-1zm1 7a1 1 0 000 2h16a1 1 0 000-2H24zm12-26a1 1 0 00-2 0v7a5 5 0 005 5h6a1 1 0 000-2h-6a3 3 0 01-3-3v-7z" }),
            React.createElement("path", { className: "primary-fill", fillRule: "evenodd", d: "M13 15.636C13 13.086 15.05 11 17.6 11h17.53a3 3 0 012.194.953l12.87 13.788c.518.556.806 1.288.806 2.047v20.576C51 50.914 48.95 53 46.4 53H17.6c-2.55 0-4.6-2.085-4.6-4.636V15.636zM17.6 13c-1.427 0-2.6 1.17-2.6 2.636v32.728C15 49.829 16.174 51 17.6 51h28.8c1.427 0 2.6-1.17 2.6-2.636V27.788a.999.999 0 00-.269-.682l-12.87-13.788a.995.995 0 00-.73-.318H17.6z", clipRule: "evenodd" })));
    }
    exports.EmptytableIcon64 = EmptytableIcon64;
});
