define(["require", "exports", "react"], function (require, exports, React) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    /**
     * icon_source: ic_sts_checkboxtick_default_16_w.svg
     */
    function CheckboxtickIcon16(props) {
        return (React.createElement("svg", { width: "16", height: "16", fill: "none", viewBox: "0 0 16 16", className: "svg-icon " + (props.table ? 'table-icon ' : '') + (props.className || ''), "data-test-id": props['data-test-id'], name: "CheckboxtickIcon16", focusable: "false" },
            React.createElement("path", { fill: "#fff", fillRule: "evenodd", d: "M12.74 4.327a1 1 0 01-.067 1.413l-5.5 5a1 1 0 01-1.38-.033l-2.5-2.5a1 1 0 011.414-1.414l1.826 1.826 4.794-4.359a1 1 0 011.413.067z", clipRule: "evenodd" })));
    }
    exports.CheckboxtickIcon16 = CheckboxtickIcon16;
});
