define(["require", "exports", "react"], function (require, exports, React) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    /**
     * icon_source: ic_sts_info_default_16_w.svg
     */
    function InfoIcon16(props) {
        return (React.createElement("svg", { width: "16", height: "16", fill: "none", viewBox: "0 0 16 16", className: "svg-icon " + (props.table ? 'table-icon ' : '') + (props.className || ''), "data-test-id": props['data-test-id'], name: "InfoIcon16", focusable: "false" },
            React.createElement("path", { fill: "#1358BF", d: "M8 16A8 8 0 108 0a8 8 0 000 16z" }),
            React.createElement("path", { fill: "#fff", d: "M9 4a1 1 0 11-2 0 1 1 0 012 0zM8 7a1 1 0 00-1 1v4a1 1 0 102 0V8a1 1 0 00-1-1z" })));
    }
    exports.InfoIcon16 = InfoIcon16;
});
