define(["require", "exports", "react"], function (require, exports, React) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    /**
     * icon_source: ic_ani_spinnersmall_default_20_w.svg
     */
    function SpinnersmallIcon20(props) {
        return (React.createElement("svg", { width: "20", height: "20", fill: "none", viewBox: "0 0 20 20", className: "svg-icon " + (props.table ? 'table-icon ' : '') + (props.className || ''), "data-test-id": props['data-test-id'], name: "SpinnersmallIcon20", focusable: "false" },
            React.createElement("path", { fill: "url(#paint0_radial_spinnersmall)", d: "M10.025 3c-3.876 0-7.018 3.134-7.018 7s3.142 7 7.018 7a7.013 7.013 0 006.946-6H20c-.503 5.053-4.777 9-9.975 9C4.488 20 0 15.523 0 10S4.488 0 10.025 0c.83 0 1.503.672 1.503 1.5S10.855 3 10.025 3z" }),
            React.createElement("defs", null,
                React.createElement("radialGradient", { id: "paint0_radial_spinnersmall", cx: "0", cy: "0", r: "1", gradientTransform: "matrix(-11.10714 21.99603 -11.00484 -5.55702 18.997 9.339)", gradientUnits: "userSpaceOnUse" },
                    React.createElement("stop", { offset: ".141", stopColor: "#107F8C", stopOpacity: "0" }),
                    React.createElement("stop", { offset: "1", stopColor: "#107F8C" })))));
    }
    exports.SpinnersmallIcon20 = SpinnersmallIcon20;
});
