define(["require", "exports", "react"], function (require, exports, React) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    /**
     * @deprecated
     * icon_source: ic_sts_question_default_16_w.svg
     */
    function QuestionIcon16(props) {
        return (React.createElement("svg", { width: "16", height: "16", fill: "none", viewBox: "0 0 16 16", className: "svg-icon " + (props.table ? 'table-icon ' : '') + (props.className || ''), "data-test-id": props['data-test-id'], name: "QuestionIcon16", focusable: "false" },
            React.createElement("path", { fill: "#B2B8BF", d: "M8 16A8 8 0 118 0a8 8 0 010 16z" }),
            React.createElement("path", { fill: "#fff", d: "M7.133 5.5A1 1 0 118 7a1 1 0 00-1 1v1a1 1 0 002 0v-.17A3.001 3.001 0 105.402 4.5a1 1 0 001.731 1zM8 13a1 1 0 100-2 1 1 0 000 2z" })));
    }
    exports.QuestionIcon16 = QuestionIcon16;
});
