define(["require", "exports", "react"], function (require, exports, React) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    /**
     * icon_source: ic_sts_notificationalert_default_20_w.svg
     */
    function NotificationalertIcon20(props) {
        return (React.createElement("svg", { width: "20", height: "20", fill: "none", viewBox: "0 0 20 20", className: "svg-icon " + (props.table ? 'table-icon ' : '') + (props.className || ''), "data-test-id": props['data-test-id'], name: "NotificationalertIcon20", focusable: "false" },
            React.createElement("path", { fill: "#F90", d: "M9 5a1 1 0 012 0v6a1 1 0 11-2 0V5zM10 16a1 1 0 100-2 1 1 0 000 2z" }),
            React.createElement("path", { fill: "#F90", fillRule: "evenodd", d: "M10 0C4.486 0 0 4.485 0 10s4.486 10 10 10 10-4.485 10-10S15.514 0 10 0zM2 10c0-4.41 3.59-8 8-8s8 3.59 8 8-3.59 8-8 8-8-3.59-8-8z", clipRule: "evenodd" })));
    }
    exports.NotificationalertIcon20 = NotificationalertIcon20;
});
