define(["require", "exports", "react"], function (require, exports, React) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    /**
     * icon_source: ic_srv_edittext_default_16_w.svg
     */
    function EdittextIcon16(props) {
        return (React.createElement("svg", { width: "16", height: "16", fill: "none", viewBox: "0 0 16 16", className: "svg-icon " + (props.table ? 'table-icon ' : '') + (props.className || ''), "data-test-id": props['data-test-id'], name: "EdittextIcon16", focusable: "false" },
            React.createElement("path", { className: "primary-fill", d: "M2.916 10.241a1 1 0 00-.234.37L1.53 13.814a.5.5 0 00.64.64l3.205-1.151a.999.999 0 00.37-.234L11.712 7.1 8.884 4.273 2.916 10.24zM9.945 3.212l2.828 2.829 1.42-1.42a1 1 0 000-1.414l-1.415-1.414a1 1 0 00-1.414 0l-1.42 1.42z" })));
    }
    exports.EdittextIcon16 = EdittextIcon16;
});
