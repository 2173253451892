define(["require", "exports", "react"], function (require, exports, React) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    /**
     * icon_source: ic_srvx_sortdecrease_default_16_w.svg
     */
    function SortdecreaseSrvxIcon16(props) {
        return (React.createElement("svg", { width: "16", height: "16", fill: "none", viewBox: "0 0 16 16", className: "svg-icon " + (props.table ? 'table-icon ' : '') + (props.className || ''), "data-test-id": props['data-test-id'], name: "SortdecreaseSrvxIcon16", focusable: "false" },
            React.createElement("path", { className: "sort-fill", d: "M9 12a1 1 0 11-2 0V5.58l-.875.7a1 1 0 11-1.25-1.56l2.5-2a1 1 0 011.25 0l2.5 2a1 1 0 11-1.25 1.56L9 5.58V12z" })));
    }
    exports.SortdecreaseSrvxIcon16 = SortdecreaseSrvxIcon16;
});
