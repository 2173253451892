define(["require", "exports", "react"], function (require, exports, React) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    /**
     * icon_source: ic_sts_notificationerror_default_20_w.svg
     */
    function NotificationerrorIcon20(props) {
        return (React.createElement("svg", { width: "20", height: "20", fill: "none", viewBox: "0 0 20 20", className: "svg-icon " + (props.table ? 'table-icon ' : '') + (props.className || ''), "data-test-id": props['data-test-id'], name: "NotificationerrorIcon20", focusable: "false" },
            React.createElement("path", { fill: "#C11030", d: "M14.21 12.806L11.402 10l2.806-2.806a.992.992 0 10-1.403-1.403L10 8.597 7.194 5.79A.992.992 0 105.79 7.194L8.597 10 5.79 12.806a.992.992 0 101.403 1.403L10 11.403l2.806 2.806a.992.992 0 101.403-1.403z" }),
            React.createElement("path", { fill: "#C11030", fillRule: "evenodd", d: "M10 20C4.486 20 0 15.515 0 10S4.486 0 10 0s10 4.485 10 10-4.486 10-10 10zm0-2c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z", clipRule: "evenodd" })));
    }
    exports.NotificationerrorIcon20 = NotificationerrorIcon20;
});
