define(["require", "exports", "react"], function (require, exports, React) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    /**
     * icon_source: ic_srvx_sort_default_16_w.svg
     */
    function SortSrvxIcon16(props) {
        return (React.createElement("svg", { width: "16", height: "16", fill: "none", viewBox: "0 0 16 16", className: "svg-icon " + (props.table ? 'table-icon ' : '') + (props.className || ''), "data-test-id": props['data-test-id'], name: "SortSrvxIcon16", focusable: "false" },
            React.createElement("path", { fill: "#7D838A", d: "M6.125 6.28a1 1 0 11-1.25-1.56l2.5-2a1 1 0 011.25 0l2.5 2a1 1 0 11-1.25 1.56L8 4.78l-1.875 1.5zM6.125 9.72a1 1 0 10-1.25 1.56l2.5 2a1 1 0 001.25 0l2.5-2a1 1 0 10-1.25-1.56L8 11.22l-1.875-1.5z" })));
    }
    exports.SortSrvxIcon16 = SortSrvxIcon16;
});
