define(["require", "exports", "react"], function (require, exports, React) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    /**
     * icon_source: ic_srvx_caretdown_default_16_w.svg
     */
    function CaretdownSrvxIcon16(props) {
        return (React.createElement("svg", { width: "16", height: "16", fill: "none", viewBox: "0 0 16 16", className: "svg-icon " + (props.table ? 'table-icon ' : '') + (props.className || ''), "data-test-id": props['data-test-id'], name: "CaretdownSrvxIcon16", focusable: "false" },
            React.createElement("path", { className: "caretdown-fill", fillRule: "evenodd", d: "M2.753 5.336a1 1 0 011.411-.083L8 8.663l3.836-3.41a1 1 0 011.328 1.494l-4.5 4a1 1 0 01-1.328 0l-4.5-4a1 1 0 01-.083-1.411z", clipRule: "evenodd" })));
    }
    exports.CaretdownSrvxIcon16 = CaretdownSrvxIcon16;
});
