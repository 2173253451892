define(["require", "exports", "react"], function (require, exports, React) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    /**
     * icon_source: ic_sts_error_default_16_w.svg
     */
    function ErrorIcon16(props) {
        return (React.createElement("svg", { width: "16", height: "16", fill: "none", viewBox: "0 0 16 16", className: "svg-icon " + (props.table ? 'table-icon ' : '') + (props.className || ''), "data-test-id": props['data-test-id'], name: "ErrorIcon16", focusable: "false" },
            React.createElement("path", { fill: "#C11030", d: "M8 16c-4.411 0-8-3.588-8-8s3.589-8 8-8 8 3.588 8 8-3.589 8-8 8z" }),
            React.createElement("path", { fill: "#fff", fillRule: "evenodd", d: "M9.403 8l1.806 1.806a.992.992 0 11-1.403 1.403L8 9.403 6.194 11.21A.992.992 0 114.79 9.806L6.597 8 4.79 6.194A.992.992 0 116.194 4.79L8 6.597 9.806 4.79a.992.992 0 111.403 1.403L9.403 8z", clipRule: "evenodd" })));
    }
    exports.ErrorIcon16 = ErrorIcon16;
});
