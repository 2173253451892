define(["require", "exports", "react"], function (require, exports, React) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    /**
     * icon_source: ic_sts_warning_default_16_w.svg
     */
    function WarningIcon16(props) {
        return (React.createElement("svg", { width: "16", height: "16", fill: "none", viewBox: "0 0 16 16", className: "svg-icon " + (props.table ? 'table-icon ' : '') + (props.className || ''), "data-test-id": props['data-test-id'], name: "WarningIcon16", focusable: "false" },
            React.createElement("path", { fill: "#F90", d: "M8 0C3.589 0 0 3.588 0 8s3.589 8 8 8 8-3.588 8-8-3.589-8-8-8z" }),
            React.createElement("path", { fill: "#fff", d: "M8 3a1 1 0 00-1 1v4a1 1 0 002 0V4a1 1 0 00-1-1zM9 12a1 1 0 11-2 0 1 1 0 012 0z" })));
    }
    exports.WarningIcon16 = WarningIcon16;
});
