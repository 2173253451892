define(["require", "exports", "react"], function (require, exports, React) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    /**
     * icon_source: ic_ani_spinnerlarge_default_64_w.svg
     */
    function SpinnerlargeIcon64(props) {
        return (React.createElement("svg", { width: "64", height: "64", fill: "none", viewBox: "0 0 64 64", className: "svg-icon " + (props.table ? 'table-icon ' : '') + (props.className || ''), "data-test-id": props['data-test-id'], name: "SpinnerlargeIcon64", focusable: "false" },
            React.createElement("path", { fill: "url(#paint0_radial_spinnerlarge)", d: "M32 14c-9.941 0-18 8.059-18 18s8.059 18 18 18 18-8.059 18-18h6c0 13.255-10.745 24-24 24S8 45.255 8 32 18.745 8 32 8a3 3 0 110 6z" }),
            React.createElement("defs", null,
                React.createElement("radialGradient", { id: "paint0_radial_spinnerlarge", cx: "0", cy: "0", r: "1", gradientTransform: "rotate(116.792 17.44 31.694) scale(59.139 29.5879)", gradientUnits: "userSpaceOnUse" },
                    React.createElement("stop", { offset: ".141", stopColor: "#107F8C", stopOpacity: "0" }),
                    React.createElement("stop", { offset: "1", stopColor: "#107F8C" })))));
    }
    exports.SpinnerlargeIcon64 = SpinnerlargeIcon64;
});
