define(["require", "exports", "react"], function (require, exports, React) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    /**
     * icon_source: ic_sts_notificationok_default_20_w.svg
     */
    function NotificationokIcon20(props) {
        return (React.createElement("svg", { width: "20", height: "20", fill: "none", viewBox: "0 0 20 20", className: "svg-icon " + (props.table ? 'table-icon ' : '') + (props.className || ''), "data-test-id": props['data-test-id'], name: "NotificationokIcon20", focusable: "false" },
            React.createElement("path", { fill: "#107F8C", d: "M13.443 7.5a1 1 0 111.414 1.414l-4.95 4.95a.998.998 0 01-.746.292.998.998 0 01-.747-.292l-2.828-2.829A1 1 0 117 9.622l2.16 2.161L13.444 7.5z" }),
            React.createElement("path", { fill: "#107F8C", fillRule: "evenodd", d: "M10 0C4.486 0 0 4.485 0 10s4.486 10 10 10 10-4.485 10-10S15.514 0 10 0zM2 10c0-4.41 3.59-8 8-8s8 3.59 8 8-3.59 8-8 8-8-3.59-8-8z", clipRule: "evenodd" })));
    }
    exports.NotificationokIcon20 = NotificationokIcon20;
});
