define(["require", "exports", "react"], function (require, exports, React) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    /**
     * icon_source: ic_srvx_caretleft_default_24_w.svg
     */
    function CaretleftIcon24(props) {
        return (React.createElement("svg", { width: "24", height: "24", fill: "none", viewBox: "0 0 24 24", className: "svg-icon " + (props.table ? 'table-icon ' : '') + (props.className || ''), "data-test-id": props['data-test-id'], name: "CaretleftIcon24", focusable: "false" },
            React.createElement("path", { className: "primary-fill caretside-fill", fillRule: "evenodd", d: "M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12zm1.26-14.85a1 1 0 00-1.52-1.3l-3 3.5a1 1 0 000 1.3l3 3.5a1 1 0 001.52-1.3L10.816 12l2.442-2.85z", clipRule: "evenodd" })));
    }
    exports.CaretleftIcon24 = CaretleftIcon24;
});
