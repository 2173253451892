define(["require", "exports", "react"], function (require, exports, React) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    /**
     * icon_source: ic_srv_closenotification_default_16_w.svg
     */
    function ClosenotificationIcon16(props) {
        return (React.createElement("svg", { width: "16", height: "16", fill: "none", viewBox: "0 0 16 16", className: "svg-icon " + (props.table ? 'table-icon ' : '') + (props.className || ''), "data-test-id": props['data-test-id'], name: "ClosenotificationIcon16", focusable: "false" },
            React.createElement("path", { className: "primary-fill", d: "M11.293 3.293a1 1 0 111.414 1.414L9.414 8l3.293 3.293a1 1 0 01-1.414 1.414L8 9.414l-3.293 3.293a1 1 0 01-1.414-1.414L6.586 8 3.293 4.707a1 1 0 011.414-1.414L8 6.586l3.293-3.293z" })));
    }
    exports.ClosenotificationIcon16 = ClosenotificationIcon16;
});
