define(["require", "exports", "react"], function (require, exports, React) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    /**
     * icon_source: ic_prd_reportstogovernment_default_20_w.svg
     */
    function ReportstogovernmentIcon20(props) {
        return (React.createElement("svg", { width: "20", height: "20", fill: "none", viewBox: "0 0 20 20", className: "svg-icon " + (props.table ? 'table-icon ' : '') + (props.className || ''), "data-test-id": props['data-test-id'], name: "ReportstogovernmentIcon20", focusable: "false" },
            React.createElement("path", { className: "product-fill", d: "M3 3a1 1 0 011-1h3a1 1 0 000-2H4a3 3 0 00-3 3v14a3 3 0 003 3h10a3 3 0 003-3v-2a1 1 0 10-2 0v2a1 1 0 01-1 1H4a1 1 0 01-1-1V3z" }),
            React.createElement("path", { className: "product-fill", d: "M5 10a1 1 0 011-1h1a1 1 0 110 2H6a1 1 0 01-1-1zM6 13a1 1 0 100 2h3a1 1 0 100-2H6zM14.649.064a1 1 0 01.702 0l4 1.5a1 1 0 01-.702 1.872L15 2.068l-3.649 1.368a1 1 0 11-.702-1.872l4-1.5zM12 4a1 1 0 011 1v3a1 1 0 11-2 0V5a1 1 0 011-1zM16 5a1 1 0 10-2 0v3a1 1 0 102 0V5zM18 4a1 1 0 011 1v3a1 1 0 11-2 0V5a1 1 0 011-1zM11 10a1 1 0 100 2h8a1 1 0 100-2h-8z" })));
    }
    exports.ReportstogovernmentIcon20 = ReportstogovernmentIcon20;
});
