define(["require", "exports", "react"], function (require, exports, React) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    /**
     * icon_source: ic_srvx_caretright_default_24_w.svg
     */
    function CaretrightIcon24(props) {
        return (React.createElement("svg", { width: "24", height: "24", fill: "none", viewBox: "0 0 24 24", className: "svg-icon " + (props.table ? 'table-icon ' : '') + (props.className || ''), "data-test-id": props['data-test-id'], name: "CaretrightIcon24", focusable: "false" },
            React.createElement("path", { className: "primary-fill caretside-fill", fillRule: "evenodd", d: "M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12zM10.74 9.15a1 1 0 011.52-1.3l3 3.5a1 1 0 010 1.3l-3 3.5a1 1 0 01-1.52-1.3L13.184 12 10.74 9.15z", clipRule: "evenodd" })));
    }
    exports.CaretrightIcon24 = CaretrightIcon24;
});
