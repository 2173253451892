define(["require", "exports", "react"], function (require, exports, React) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    /**
     * icon_source: ic_srv_calendar_default_20_w.svg
     */
    function CalendarIcon20(props) {
        return (React.createElement("svg", { width: "20", height: "20", fill: "none", viewBox: "0 0 20 20", className: "svg-icon " + (props.table ? 'table-icon ' : '') + (props.className || ''), "data-test-id": props['data-test-id'], name: "CalendarIcon20", focusable: "false" },
            React.createElement("path", { className: "primary-fill", fillRule: "evenodd", d: "M7 1a1 1 0 00-2 0v1H3a3 3 0 00-3 3v12a3 3 0 003 3h14a3 3 0 003-3V5a3 3 0 00-3-3h-2V1a1 1 0 10-2 0v1H7V1zm6 4V4H7v1a1 1 0 01-2 0V4H3a1 1 0 00-1 1v3h16V5a1 1 0 00-1-1h-2v1a1 1 0 11-2 0zm5 5H2v7a1 1 0 001 1h14a1 1 0 001-1v-7z", clipRule: "evenodd" })));
    }
    exports.CalendarIcon20 = CalendarIcon20;
});
